import React from "react"
import "./thankyou.css"

export default function ThankYou() {
    return (
        <div className="page-w">
    <div className="page-content">
      <div className="section-raffle-t-hero wf-section">
        <div className="discord-hero-bg-w discord-hero-bg-w-raffle-t">
          <div className="discord-hero-bg-c discord-hero-bg-c-raffle-t">
            <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-raffle-t"><img src="images/raffle-t-bg-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 427.1875px, (max-width: 991px) 453.890625px, 534px" srcset="images/raffle-t-bg-1-p-500.png 500w, images/raffle-t-bg-1.png 534w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-raffle-t"/></div>
            <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-raffle-t"><img src="images/raffle-t-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 427.1875px, (max-width: 991px) 453.890625px, 534px" srcset="images/raffle-t-bg-2-p-500.png 500w, images/raffle-t-bg-2.png 534w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-raffle-t"/></div>
            <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-raffle-t"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440px" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-raffle-t"/></div>
            <div className="raffle-hero-bg-grey"></div>
          </div>
        </div>
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
          <div className="page-padd">
            <div className="page-container-wide">
              <div className="padd-vert-navbar">
                <div className="navbar-cont-w">
                  <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                    <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                  </a>
                  <nav role="navigation" className="nav-menu-2 w-nav-menu">
                    <div className="navbar-button-w-mob">
                      <div className="navbar-button-b">
                        <a href="#" className="button w-inline-block">
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </nav>
                  <div className="navbar-cont-c-r">
                    <div className="navbar-button-w">
                      <div className="navbar-button-b">
                        <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                          <div className="button-hover-b"></div>
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="menu-button w-nav-button">
                      <div className="w-icon-nav-menu"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-discord-hero padd-vert-discord-hero-raffle-t">
              <div className="discord-hero-cont-w discord-hero-cont-w-raffle-t">
                <div className="discord-hero-cont-c discord-hero-cont-c-raffle-t">
                  <div className="raffle-t-hero-head-b">
                    <h3 className="raffle-hero-head-e raffle-hero-head-e-t">Thank You for registering!</h3>
                  </div>
                  <div className="raffle-t-hero-subhead-b-2">
                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-t">Congratulations you have been selected for the mint 1 get 1 free offer you will be able to mint on</h3>
                  </div>
                  <div className="raffle-t-date-c">
                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-t-date">Wednesday February 23rd at 5PM CET.</h3>
                  </div>
                  <div className="raffle-t-date-w">
                    <div className="raffle-t-hero-subhead-b">
                      <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-t">You will have the chance to get the EARLY HOLDERS OFFER if you are one of the first people to MINT. (MINT 1 GET 1 FREE)</h3>
                    </div>
                  </div>
                  <div className="raffle-t-hero-subhead-2-b">
                    <h3 className="raffle-hero-subhead-2-e raffle-hero-subhead-2-e-t">REMINDER: YOU NEED TO HAVE AT LEAST 0.1 ETH + GAS FEE TO BE ABLE TO MINT</h3>
                  </div>
                  <div className="raffle-t-text-b raffle-t-text-b-2">
                    <p className="raffle-t-text-e raffle-t-text-e-2">REMINDER : Winning the raffle DOESN’T guarantee you an NFT. You need to MINT as soon as possible when the MINT opened, or the system will ping you as inactive and pick another winner.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="discord-section-footer wf-section">
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-footer-main padd-vert-footer-main-disc">
              <div className="footer-cont-w">
                <div className="footer-cont-c">
                  <div className="footer-head-b">
                    <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                      <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                    </a>
                  </div>
                  <div className="footer-social-w">
                    <div className="footer-soc-c">
                      <div className="home-hero-soc-b">
                        <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                      <div className="home-hero-soc-b">
                        <div className="button-discord-w">
                          <div className="discord-letters-w rotate-e"></div>
                          <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                            <div className="button-hover-b button-hover-b-disc"></div>
                            <div className="button-discord-circle">
                              <div className="button-discord-c">
                                <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="home-hero-soc-b">
                        <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer-copyright-b">
                    <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}