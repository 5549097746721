import { useState, useEffect } from 'react';
import Config from "../config/index.js"

export default function useIsRegistered(address) {
    const [isRegistered, setIsRegistered] = useState()

    useEffect(() => {
        if (address) {
            let _isRegistered = localStorage.getItem(Config.DATA.STORAGE_KEY)
            if (!_isRegistered) {
                setIsRegistered(false);
            } else {
                let addrs = JSON.parse(_isRegistered);
                if (addrs.includes(address)) {
                    setIsRegistered(true);
                    window.open("/thank-you", "_self")
                } else {
                    setIsRegistered(false);
                }
            }
        }      
    }, [address])

    return [
        isRegistered,
        setIsRegistered
    ]
}