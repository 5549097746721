import { useState, useEffect } from 'react';
import Config from "../config/index.js"
import axios from "axios"
import {ethers} from "ethers"

export default function useRegister(setIsRegistered, address, signer, isRegistered, isConnected, provider) {
    const [error, setError] = useState(undefined);

    const tryRegister = async () => {
        try {
            let signature = await signer.signMessage(Config.DATA.MESSAGE);
            console.log(Config.DATA.MESSAGE)
            let submitUrl = `${Config.URLS.API}?address=${address}&signature=${signature}`;
            console.log(submitUrl)
            let res = await axios.get(submitUrl);            
            console.log(res)
            return res
        } catch (e) {
            console.log(e)
        }
        return undefined;
    }

    const persistRegisterStateLocally = () => {
        setIsRegistered(true)
        let _storage = localStorage.getItem(Config.DATA.STORAGE_KEY);
        if (!_storage) {
            let toStore = JSON.stringify([address]);
            console.log(toStore)
            localStorage.setItem(Config.DATA.STORAGE_KEY, toStore);
        } else {
            _storage = JSON.parse(_storage);
            _storage.push(address)
            localStorage.setItem(Config.DATA.STORAGE_KEY, JSON.stringify(_storage));
        }
        window.open("/thank-you", "_self");
        return;
    }

    const register = async () => {
        setError(undefined);
        let balance = await provider.getBalance(address)
        if (balance.lt(ethers.BigNumber.from("100000000000000000"))) {
            setError("You need at least 0.1 ETH in your wallet to enter.")
            return;
        }
        if (!isRegistered && isConnected) {
            let res = await tryRegister();
            if (res && res.data && res.data.success) {
                persistRegisterStateLocally();
            } else if (res && res.data && res.data.error) {
                setError(res.data.error)
            } else {
                setError(Config.ERRORS.MESSAGES.UNEXPECTED_SERVER_ERROR);
            }
        }        
      }

    return [
        error,
        register
    ]
}