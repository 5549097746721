import { useState } from 'react';
import Config from "../config/index.js"
import useClock from "../hooks/useClock";

export default function useIsStarted() {
    const [now, setNow] = useState(Math.floor(Date.now() / 1000));
    const isStarted = () => now && now >= Config.DATA.RAFFLE_START;
    const isEnded = () => now && now >= Config.DATA.RAFFLE_END;
    const isOngoing = () => isStarted() && !isEnded()
    const isBeforeStart = () => !isStarted();    

    useClock(() => {
        setNow(n => Math.floor(Date.now() / 1000))
    })

    const difference = Math.max(Config.DATA.RAFFLE_START - now, 0);
    const sec = 1;
    const min = sec * 60;
    const hr = min * 60;
    const day = hr * 24;

    const textDay = Math.floor(difference / day);
    const textHour = Math.floor((difference % day) / hr);
    const textMin = Math.floor((difference % hr) / min);
    const textSec = Math.floor((difference % min) / sec);

    return [
        now,
        isStarted,
        isEnded,
        isOngoing,
        isBeforeStart,
        textDay,
        textHour,
        textMin,
        textSec
    ]
}