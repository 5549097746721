import { useState } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink"
import Portis from "@portis/web3";
import Authereum from "authereum";
import Fortmatic from "fortmatic";
import { ethers } from "ethers";

import Config from "../config";

const walletLink = new WalletLink({
    appName: "coinbase",
});

const walletLinkProvider = walletLink.makeWeb3Provider(`https://mainnet.infura.io/v3/${Config.NETWORK.INFURA_ID}`, 1);

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: Config.NETWORK.INFURA_ID,
    },
  },
  // torus: {
  //   package: Torus,
  //   options: {
  //     networkParams: {
  //       host: "https://localhost:8545", // optional
  //       chainId: 1337, // optional
  //       networkId: 1337 // optional
  //     },
  //     config: {
  //       buildEnv: "development" // optional
  //     },
  //   },
  // },
  authereum: {
    package: Authereum, // required
  },
  "custom-walletlink": {
    display: {
      logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
      name: "Coinbase",
      description: "Connect to Coinbase Wallet (not Coinbase App)",
    },
    package: walletLinkProvider,
    connector: async (provider, _options) => {
      await provider.enable();
      return provider;
    },
  },
};

if (Config.NETWORK.PORTIS_ID && Config.NETWORK.PORTIS_ID !== "") {
    providerOptions["portis"] = {
        display: {
          logo: "https://user-images.githubusercontent.com/9419140/128913641-d025bc0c-e059-42de-a57b-422f196867ce.png",
          name: "Portis",
          description: "Connect to Portis App",
        },
        package: Portis,
        options: {
          id: Config.NETWORK.PORTIS_ID,
        },
    }
}

if (Config.NETWORK.FORTMATIC_ID && Config.NETWORK.FORTMATIC_ID !== "") {
    providerOptions["portis"] = {
        package: Fortmatic, // required
        options: {
          key: Config.NETWORK.FORTMATIC_ID, // required
        },
      }
}

const web3Modal = new Web3Modal({
    //cacheProvider: true,
    providerOptions,
    //disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useWeb3() {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [address, setAddress] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isCorrectChain, setIsCorrectChain] = useState(undefined)
    const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);

    async function initiateWeb3(proxy) {
        const _provider = new ethers.providers.Web3Provider(proxy);

        _provider.on("disconnect", (code, reason) => {
            console.log(code, reason);
            setIsConnected(false)
        });

        const _signer = await _provider.getSigner();
        const _address = await _signer.getAddress();        
        let { chainId } = await _provider.getNetwork();
        let _isCorrectChainId = chainId === Config.NETWORK.TARGET_CHAIN_ID
        chainId = chainId.toString()       

        if (!_isCorrectChainId) {
            alert(`Wrong network. Please connect to the ${Config.NETWORK.CHAIN_ID_TO_NAME[Config.NETWORK.TARGET_CHAIN_ID]} network`)
            return;
        }

        setProvider(_provider);
        setSigner(_signer);
        setAddress(_address);
        setIsConnected(true)
        setIsCorrectChain(_isCorrectChainId);        
    }

    async function tryAutoConnect() {
        try {
            let { cachedProvider } = web3Modal;
            let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
    
            if(cachedProvider && cachedProvider !== "") {
                await initiateWeb3(await web3Modal.connectTo(cachedProvider))
                return true
            } else if (cachedFromStorage && cachedFromStorage != "") {
                await initiateWeb3(await web3Modal.connectTo(cachedFromStorage))
                return true
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async function connect() {
        try {
           // await web3Modal.clearCachedProvider();
            await initiateWeb3(await web3Modal.connect())
        } catch (e) {
            if (e && e.message)
                alert(e.message)
        }
    }

    return [
        provider,
        signer,
        address,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ]
}