const Config = {
    DATA: {
        RAFFLE_START: 0,
        RAFFLE_END: 1645545600 + 3600 * 22,
        MESSAGE: "BORED DOGE CLUB OFFICIAL RAFFLE REGISTRATION",
        STORAGE_KEY: "BORED_DOGE_CLUB_RAFFLE_STORAGE_KEY"
    },
    URLS: {
        API: "https://sqx42xdtjc.us-east-1.awsapprunner.com/register"
    },
    NETWORK: {
        TARGET_CHAIN_ID: 1,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby"
        },
        INFURA_ID: "ff5d41d5b81b4f80b325a7edcd6fe4b0",
        PORTIS_ID: "",
        FORTMATIC_ID: ""
    },
    ERRORS: {
        MESSAGES: {
            UNEXPECTED_SERVER_ERROR: "Please try again."
        }
    }
}

export default Config