import React from "react"
import Config from "../config"

export default function raffleView({
    connect,
    isConnected,
    isRegistered,
    isStarted,
    isEnded,
    isOngoing,
    isBeforeStart,
    textDay,
    textHour,
    textMin,
    textSec,
    error,
    register
}) {

    const ConnectWalletSection = () => {
        return (
            <>
                 {
                     !isConnected &&
                     <>
                        <div className="raffle-hero-head-b">
                            <h3 className="raffle-hero-head-e">Connect Your Wallet</h3>
                        </div>
                        <div className="raffle-hero-subhead-b">
                            <h3 className="raffle-hero-subhead-timer">Connect Your Wallet to be able to mint</h3>
                        </div>
                        <div className="raffle-button-w">
                            <span className="button-raffle w-button"onClick={() => { connect()}}>Connect Wallet</span>
                        </div>
                     </>
                 }
            </>
        )
    }

    const NotStartedSection = () => {
        return (
            <>
                {
                     isConnected && !isStarted() && !isEnded() && !isRegistered &&
                     <>
                        <div className="raffle-hero-head-b">
                            <h3 className="raffle-hero-head-e">Raffle starts soon</h3>
                        </div>
                     </>
                 }
            </>
        )
    }

    const RegisterSection = () => {
        return (
            <>
                {
                     isConnected && isOngoing() && !isRegistered &&
                     <>
                        <div className="raffle-hero-head-b">
                            <h3 className="raffle-hero-head-e">Register for the raffle</h3>
                        </div>
                        <div className="raffle-hero-subhead-b">
                            <h3 className="raffle-hero-subhead-timer">Press the button below to enter the raffle.</h3>
                        </div>
                        <div className="raffle-button-w">
                            <span className="button-raffle w-button"onClick={() => { register()}}>Register</span>
                        </div>
                        {
                            error &&
                            <div className="raffle-hero-subhead-b">
                                <h3 className="raffle-hero-subhead-timer" style={{color: "red"}}>{error}</h3>
                            </div>
                        }
                     </>
                 }
            </>
        )
    }

    const RegisteredSection = () => {
        return (
            <>
                {
                     isConnected && isOngoing() && isRegistered &&
                     <>
                        <div className="raffle-hero-head-b">
                            <h3 className="raffle-hero-head-e">You are registered</h3>
                        </div>
                        <div className="raffle-hero-subhead-b">
                            <h3 className="raffle-hero-subhead-timer">Redirecting you to another page...    </h3>
                        </div>
                        {
                            error &&
                            <div className="raffle-hero-subhead-b">
                                <h3 className="raffle-hero-subhead-timer" style={{color: "red"}}>An error occured. Please try again.</h3>
                            </div>
                        }
                     </>
                 }
            </>
        )
    }

    const AfterRegisterSection = () => {
        return (
            <>  
                {
                     isConnected && isEnded() &&
                     <>
                        <div className="raffle-hero-head-b">
                            <h3 className="raffle-hero-head-e">Raffle closed</h3>
                        </div>
                        <div className="raffle-hero-subhead-b">
                            <h3 className="raffle-hero-subhead-timer">Minting starts at February 23rd 5PM CET.</h3>
                        </div>
                     </>
                 }
            </>
        )
    }

    return (
        <>
            <div className="page-w">
    <div className="page-content">
      <div className="section-raffle-hero wf-section">
        <div className="home-hero-bg-w home-hero-bg-w-raffle">
          <div className="home-hero-bg-c home-hero-bg-c-raffle">
            <div className="home-hero-bg-e-dog-bg home-hero-bg-e-dog-bg-raffle"></div>
            <div className="home-hero-bg-b-dog home-hero-bg-b-dog-raffle"><img src="images/home-hero-bg-dog-075.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 758.875px, (max-width: 1439px) 665.515625px, (max-width: 1919px) 693.171875px, 727.75px" srcset="images/home-hero-bg-dog-075-p-500.png 500w, images/home-hero-bg-dog-075.png 559w" alt="" className="home-hero-bg-e-dog home-hero-bg-e-dog-raffle"/></div>
            <div className="home-hero-bg-b-smoke home-hero-bg-b-smoke-raffle"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="100vw" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="home-hero-bg-e-smoke"/></div>
          </div>
        </div>
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
          <div className="page-padd">
            <div className="page-container-wide">
              <div className="padd-vert-navbar">
                <div className="navbar-cont-w">
                  <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                    <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                  </a>
                  <nav role="navigation" className="nav-menu-2 w-nav-menu">
                    <div className="navbar-button-w-mob">
                      <div className="navbar-button-b">
                        <a href="#" className="button w-inline-block">
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </nav>
                  <div className="navbar-cont-c-r">
                    <div className="navbar-button-w">
                      <div className="navbar-button-b">
                        <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                          <div className="button-hover-b"></div>
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="menu-button w-nav-button">
                      <div className="w-icon-nav-menu"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-home-hero padd-vert-home-hero-raffle">
              <div className="home-hero-cont-w home-hero-cont-w-raffle">
                <div className="home-hero-cont-c home-hero-cont-c-raffle">
                  <ConnectWalletSection />
                  <NotStartedSection />
                  <RegisterSection />
                  <RegisteredSection />
                  <AfterRegisterSection />
                  <div className="raffle-hero-subhead-2-b">
                    <h3 className="raffle-hero-subhead-2-e"><span className="text-cap">RAFFLE OPENS  AT 5 PM CET FOR 12 HOURS</span></h3>
                  </div>
                  <div className="timer-w">
                    <div className="timer-c">
                      <div className="timer-block-c">
                        <div className="timer-number-b">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-hour-10">
                                <div id="timer-num-c-hour-10" className="timer-num-c-hour-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-hour-1">
                                <div id="timer-num-c-hour-1" className="timer-num-c-hour-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Hours</div>
                        </div>
                      </div>
                      <div className="timer-block-c">
                        <div className="timer-number-b">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-min-10">
                                <div className="timer-num-c-min-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-min-1">
                                <div className="timer-num-c-min-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Minutes</div>
                        </div>
                      </div>
                      <div className="timer-block-c">
                        <div className="timer-number-b">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-sec-10">
                                <div className="timer-num-c-sec-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-sec-1">
                                <div className="timer-num-c-sec-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="discord-section-footer wf-section">
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-footer-main padd-vert-footer-main-disc">
              <div className="footer-cont-w">
                <div className="footer-cont-c">
                  <div className="footer-head-b">
                    <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                      <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                    </a>
                  </div>
                  <div className="footer-social-w">
                    <div className="footer-soc-c">
                      <div className="home-hero-soc-b">
                        <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                      <div className="home-hero-soc-b">
                        <div className="button-discord-w">
                          <div className="discord-letters-w rotate-e"></div>
                          <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                            <div className="button-hover-b button-hover-b-disc"></div>
                            <div className="button-discord-circle">
                              <div className="button-discord-c">
                                <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="home-hero-soc-b">
                        <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer-copyright-b">
                    <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        </>
    )
}