import React, { useState } from "react";
import axios from 'axios'
import useWeb3 from "../hooks/useWeb3";
import useIsRegistered from "../hooks/useIsRegistered";
import useIsStarted from "../hooks/useIsStarted";
import useRegister from "../hooks/useRegister";
import Config from "../config/index.js"
import RaffleView from "../components/raffleView";
import "./index.css"

export default function Index () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    /** REGISTERED STATE */
    const [
        isRegistered,
        setIsRegistered
    ] = useIsRegistered(address);

    /** TIMING */
    const [        
        now,
        isStarted,
        isEnded,
        isOngoing,
        isBeforeStart,
        textDay,
        textHour,
        textMin,
        textSec        
    ] = useIsStarted();

    const [
        error,
        register
    ] = useRegister(setIsRegistered, address, signer, isRegistered, isConnected, provider);


    return (
        <RaffleView
            connect={connect}
            isConnected={isConnected}
            isRegistered={isRegistered}
            isStarted={isStarted}
            isEnded={isEnded}
            isOngoing={isOngoing}
            isBeforeStart={isBeforeStart}
            textDay={textDay}
            textHour={textHour}
            textMin={textMin}
            textSec={textSec}
            error={error}
            register={register}
        />
    )
}